.sidebar {
  width: 200px;
  min-height: 600px;
  padding-top: 20px;
  border-right: 1px solid #d0d0d0;
  width: 300px;

  :global {
    .ant-tree {
      font-size: 16px;
    }

    .new-file-tag {
      margin-left: -3px;
    }

    .ant-tree-iconEle {
      > span {
        font-size: 1.25em;
      }
    }
  }
}

.folderName {
  margin-left: 4px;
}

.actions {
  width: 100%;
  padding: 0 1rem 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px dashed #d0d0d0;
}
