.files {
  background: #fff;
  padding: 1rem;
}

.table {
  margin-top: 0;
}

:global {
  .only-control-file {
    td:not(.actions) {
      opacity: .6;
    }
  }
}