@import '~antd/dist/antd.css';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-layout {
  max-width: 1440px;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
}

.ant-layout-header {
  background: #fff;
  height: auto;
}

.ant-layout-sider {
  background: #fff;
}

.ant-layout-content {
  padding: 24px 50px;
}

.ant-table {
  margin-top: 1.5rem;
}

.ant-spin-nested-loading {
  .ant-spin-blur {
    .ant-spin-nested-loading {
      .ant-spin-spinning {
        display: none;
      }
    }
  }
}

.api-fail-list {
  padding-left: 1rem ;
}

.empty-data {
  color: rgba(0, 0, 0, .25);
}

.anticon {
  &.new-file-tag {
    color: red;
  }
}

// Table
.ant-table-content {
  tr {
    th,
    td {
      text-align: center;
    }
    td.log-description {
      text-align: left;
    }
  }
}

.ant-progress-inner {
  border: 1px solid rgba($color: #1890ff, $alpha: .5);
}

.large-file-progress {
  max-width: 300px;
  margin: 1rem auto;
}

// Login Page.
.login {
  text-align: center;
}
