.options {
  display: flex;
  align-items: center;
  padding: 12px 0;
  background: #fff;
  border-bottom: 1px solid #d0d0d0;
}

.blobs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 350px;
  padding-left: 12px;
  padding-right: 12px;
  border-right: 1px solid #d0d0d0;
  font-size: 16px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 350px);
  padding-left: 12px;
  padding-right: 12px;
}

.buttons {
  display: flex;
  align-items: center;

  button {
    margin-left: 12px;
  }
}

.breadcrumb {
  cursor: pointer;
}

.filePath {
  margin-bottom: 20px;
}

.blobListWithDelete {
  display:  flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.blobAction {
  :global {
    .ant-dropdown-menu-item {
      text-align: center;

      &:hover,
      &.ant-dropdown-menu-item-active {
        background-color: #fff;
      }
    }

    .blob-title {
      max-width: 295px;
    }
  }
}
