.multipleUpload {
  :global {
    .msg {
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .msg-popup-tips {
      color: #00000073;
      margin-bottom: 1rem;
    }

    .msg-tips {
      color: #00000073;
    }

    .msg-error {
      color: #f5222d;
    }
  }
}

.uploadList {
  margin: 1rem 0;

  :global {
    .file-item {
      display: flex;
      padding: 6px 3px;
      align-items: center;
      transition: all ease-in .3s;
      word-break: break-all;
      cursor: text;
      color: rgba(0, 0, 0, .85);
      width: 100%;

      .anticon {
        opacity: .4;

        &:hover {
          opacity: .8;
        }
      }

      .file-index {
        min-width: 25px;
      }

      .file-name {
        width: 100%;
        padding: 0 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .file-action {
        display: none;

        button {
          width: 20px;
          height: 20px;
        }
      }

      &:hover {
        background-color: #f5f5f5;

        .file-action {
          display: inline-block;
        }
      }
    }

    .size-valid-error {
      .file-name {
        color: #1890ff;
      }
    }
  }
}