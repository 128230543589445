.top {
  display: flex;
  justify-content: space-between;
}

.logo {
  display: block;
  font-size: 30px;

  img {
    width: auto;
    height: 55px;
  }
}

.name {
  cursor: pointer;
  margin: 0 10px;
}
